var newsTabs = (function() {
  var t = {};
  t.container = null;
  t.events = function() {
    $('.tabs a', t.container).click(function(e) {
      e.preventDefault();

      $(this)
        .parents('li')
        .addClass('current')
        .siblings('li')
        .removeClass('current');

      t.loadContent($(this).attr('href'));
    });
  };
  t.loadContent = function(url) {
    if(t.container.hasClass('news-list')) {
      $('.news-results', t.container).load(url + ' .news-results .news-content', function() {
        ga('send', 'pageview', url);
        history.pushState({}, '', url);
      });
    }

    if(t.container.hasClass('leadership-grid')) {
      $('.leadership-results', t.container).load(url + ' .leadership-results .leadership-content', function() {
        ga('send', 'pageview', url);
        history.pushState({}, '', url);
      });
    }
  };
  t.init = function() {
    t.container = $('.news-list, .leadership-grid');
    if(t.container.length) {
      t.events();
    }
  };
  return t;
})();

$(function() {
  newsTabs.init();
});