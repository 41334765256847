var slider = (function() {
  var t = {};
  t.events = function() {
    $('.slide-nav').on('click', 'a', function(e) {
      e.preventDefault();
      $(this)
        .parents('li').addClass('active')
        .siblings('li').removeClass('active');

      var slide = $('.slide' + $(this).attr('href'));
      slide.siblings('.slide').removeClass('active');
      setTimeout(function() {
        slide.addClass('active');
      }, 750);
    });

    $('.slide-pager a').click(function(e) {
      e.preventDefault();
      var parent = $(this).closest('.slider');
      var active = $('.slide-nav li.active', parent);
      var next;
      if($(this).hasClass('next')) {
        next = active.next();
        if(!next.length) {
          next = $('.slide-nav li:first-child');
        }
      } else {
        next = active.prev();
        if(!next.length) {
          next = $('.slide-nav li:last-child');
        }
      }

      next.children('a').trigger('click');
    });
  };
  t.views = function() {
    $('.slider').each(function(index, slider) {
      if($('.slide-nav ul li', slider).length) {
        return;
      }

      var slides = $('.slide', slider);

      slides.each(function(slideIndex, slide) {
        var id = $(slide).attr('id');
        var title = $('h3', slide).text();

        $('.slide-nav ul', slider).append('<li><a href="#' + id + '">' + title + '</a></li>');
      });
    });
  };
  t.init = function() {
    t.events();
    t.views();

    $('.slide-nav li:first-child a').trigger('click');
  };
  return t;
})();

$(function() {
  slider.init();
});