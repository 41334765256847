var fluidLabels = (function() {
  var t = {};
  t.container = null;
  t.events = function() {
    $('input, select', t.container).focus(function() {
      $(this).closest('.input').addClass('focus');
    });

    $('input, select', t.container).blur(function() {
      $(this).closest('.input').removeClass('focus');

      if($(this).val() !== '') {
        $(this).closest('.input').addClass('filled');
      }
    });

    $('input, select', t.container).each(function() {
      if($(this).val() !== '') {
        $(this).closest('.input').addClass('filled');
      }
    });

    $('input, select', t.container).change(function() {
      if($(this).val() !== '') {
        $(this).closest('.input').addClass('filled');
      }
    });
  };
  t.init = function() {
    t.container = $('form .input');
    if(t.container.length) {
      t.events();
    }
  };
  return t;
})();

var formSubmit = (function() {
  var t = {};
  t.container = null;
  t.events = function() {
    $(t.container).on('submit', 'form', function(e) {
      e.preventDefault();
      var fields = $(this).serializeObject();
      var action = $(this).attr('action');

      $('.submit input', t.container).addClass('disabled');

      t.container.load(action+' .contact-form', fields, function() {
        scroll_to(t.container);

        fluidLabels.init();

        if($('.form-success').length) {
          ga('send', 'pageview', '/thankyou');
        }
      });
    });
  };
  t.init = function() {
    t.container = $('.contact-form-wrapper');

    if(t.container.length) {
      t.events();
      fluidLabels.init();
    }
  };
  return t;
})();

$(function() {
  formSubmit.init();
});