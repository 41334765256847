function getURLParameter(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
}

//Soft scroll to any jQuery DOM element
function scroll_to(context, speed) {
  if(!speed) {
    speed = 400;
  }
  var offset = 0;
  if(context) {
    offset = context.offset().top;
  }
  $('body,html,document').animate({
    scrollTop: offset
  }, speed);
}

//Convert form fields to an object
jQuery.fn.serializeObject = function() {
  var o = {};
  var a = this.serializeArray();
  jQuery.each(a, function() {
    if (o[this.name] !== undefined) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    } else {
      o[this.name] = this.value || '';
    }
  });
  return o;
};

var mainNav = (function() {
  var t = {};
  t.currentSection = '';
  t.events = function() {
    var toggle = $('header a.toggle');
    var search = $('.navigation form');
    var body = $('body');

    toggle.click(function(e) {
      e.preventDefault();

      body.toggleClass('open-nav');

      if(body.hasClass('open-nav')) {
        toggle.text('close');
      } else {
        toggle.text(t.currentSection);
        body.removeClass('open-search');
      }
    });

    search.submit(function(e) {
      if($('#q').val() === '') {
        e.preventDefault();
        
        if(body.hasClass('open-search')) {
          body.removeClass('open-search');
        } else {
          body.addClass('open-search');
          $('#q').focus();
        }
      }
    });

    $('.search-toggle').click(function() {
      body.toggleClass('open-search');
    });
  };
  t.init = function() {
    t.events();

    t.currentSection = $('header a.toggle').text();
  };
  return t;
})();

var share = (function() {
  var t = {};
  t.url = '';
  t.facebook = function() {
    var url = 'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(t.url);
    return url;
  };
  t.linkedin = function() {
    var url = 'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(t.url);
    return url;
  };
  t.twitter = function() {
    var title = $('meta[property="og:title"]').attr('content');
    var url = 'https://twitter.com/intent/tweet?'+
              '&text='+encodeURIComponent(title + " " + t.url + " via @icomagencies")+
              '&source=webclient';
    return url;
  };
  t.open = function(url) {
    var h = 250;
    var w = 626;
    var wLeft = window.screenLeft ? window.screenLeft : window.screenX;
    var wTop = window.screenTop ? window.screenTop : window.screenY;

    var left = wLeft + (window.innerWidth / 2) - (w / 2);
    var top = wTop + (window.innerHeight / 2) - (h / 2);
    window.open(url, 'Share', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  };
  t.init = function() {
    t.url = window.location.href;

    $('.news-social a.facebook, .news-social a.twitter, .news-social a.linkedin').click(function(e) {
      e.preventDefault();
      var url = t.facebook();
      var network = 'Facebook';

      if($(this).hasClass('twitter')) {
        url = t.twitter();
        network = 'Twitter';
      }

      if($(this).hasClass('linkedin')) {
        url = t.linkedin();
        network = 'LinkedIn';
      }

      t.open(url);
      ga('send', 'event', network, 'Share');
    });
  };
  return t;
})();

$(function() {
  $('.no-js').removeClass('no-js');

  mainNav.init();
  share.init();

  if($('body.search').length) {
    setTimeout(function() {
      var offset = $('.section-container').offset().top - 50;
      $('body,html,document').animate({
        scrollTop: offset
      }, 400);
    }, 1000);
  }

  $('a').each(function() {
    var a, pdf, mail;
    a = new RegExp('/' + window.location.host + '/');
    pdf = new RegExp('.pdf|.zip');
    mail = new RegExp('mailto');
    if (
      (!a.test(this.href) || pdf.test(this.href)) &&
      !mail.test(this.href)
    ) {
      $(this).attr('target', '_blank');
    }
  });

  //Hack for news article selected state

  $('.news-inner .section-nav a[href="/about/news"]').parents('li').addClass('current');
});

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-21475986-1', 'auto');
ga('send', 'pageview');