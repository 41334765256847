var homeMasthead = (function() {
  var t = {};
  t.container = null;
  t.hammer = null;
  t.trackScroll = true;
  t.destroyEvents = function() {
    setTimeout(function() {
      $(window).off({
        'DOMMouseScroll wheel mousewheel MozMousePixelScroll': t.elementScroll
      });

      //t.hammer.off('swipe', t.elementSwipe);
    }, 1500);
  };
  t.elementScroll = function(e) {
    // Prevent page from scrolling
    e.preventDefault();

    if(t.trackScroll === true) {
      t.destroyEvents();
      t.showSections();
      t.trackScroll = false;
    }

    return false;
  };
  t.elementSwipe = function(e) {
    console.log(e);

    if(t.trackScroll === true) {
      t.destroyEvents();
      t.showSections();
      t.trackScroll = false;
    } else {
      scroll_to($('.intro'));
    }
  };
  t.events = function() {
    $(window).on({
      'DOMMouseScroll wheel mousewheel MozMousePixelScroll': t.elementScroll
    });

    var container = document.getElementById('home-masthead');

    t.hammer = new Hammer(container);
    t.hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
    t.hammer.on('swipe', t.elementSwipe);
  };
  t.showSections = function() {
    t.container.addClass('open');
  };
  t.setup = function() {
    scroll_to(t.container, 0);

    setTimeout(function() {
      t.container.addClass('active');
    }, 500);
  };
  t.init = function() {
    t.container = $('#home-masthead');
    if(t.container.length) {
      t.events();
      t.setup();
    }
  };
  return t;
})();

$(function() {
  homeMasthead.init();

  (function(Modernizr){

    // Here are all the values we will test. If you want to use just one or two, comment out the lines of test you don't need.
    var tests = [
      //{ name: 'svg', value: 'url(#test)' }, // False positive in IE, supports SVG clip-path, but not on HTML element
      //{ name: 'inset', value: 'inset(10px 20px 30px 40px)' },
      { name: 'circle', value: 'circle(60px at center)' },
      { name: 'ellipse', value: 'ellipse(50% 50% at 50% 50%)' },
      { name: 'polygon', value: 'polygon(50% 0%, 0% 100%, 100% 100%)' }
    ];

    var t = 0,
        name, value, prop;

    for (; t < tests.length; t++) {
      name = tests[t].name;
      value = tests[t].value;
      Modernizr.addTest('cssclippath' + name, function(){
        // Try using window.CSS.supports
        if ( 'CSS' in window && 'supports' in window.CSS ) {
          for (var i = 0; i < Modernizr._prefixes.length; i++) {
            prop = Modernizr._prefixes[i] + 'clip-path'
            
            if ( window.CSS.supports(prop,value) ) { return true; }
          }
          return false;
        }
        // Otherwise, use Modernizr.testStyles and examine the property manually
        return Modernizr.testStyles('#modernizr { '+Modernizr._prefixes.join('clip-path:'+value+'; ')+' }',function(elem, rule) {
          var style = getComputedStyle(elem),
              clip = style.clipPath;

          if ( !clip || clip == "none" ) {
            clip = false;

            for (var i = 0; i < Modernizr._domPrefixes.length; i++) {
              test = Modernizr._domPrefixes[i] + 'ClipPath';
              if ( style[test] && style[test] !== "none" ) {
                clip = true;
                break;
              }
            }
          }

          return Modernizr.testProp('clipPath') && clip;
        });
      });

    }

  })(Modernizr);
});