var filters = (function() {
  var t = {};
  t.container = null;
  t.events = function() {
    $('a', t.container).click(function(e) {
      e.preventDefault();

      var rel = $(this).data('rel');

      t.switchNav(this);
      t.switchContent(rel);
      t.history(rel, $(this).attr('href'));
    });
  };
  t.history = function(category, url) {
    if(typeof category === 'undefined') {
      category = '';
    }

    if(typeof history !== 'undefined') {
      history.pushState({category: category}, '', url);
    }
  };
  t.setup = function() {
    var category = t.container.data('current');

    if(category !== '') {
      $('a[data-rel=' + category + ']', t.container).trigger('click');
    }
  };
  t.switchContent = function(category) {
    $('.grid-item, .grid-item-member, .grid-item-leader').show();

    if(typeof category !== 'undefined') {
      var items = $('.grid-item.category-' + category + ', .grid-item-member.category-' + category + ', .grid-item-leader.category-' + category);
      items.siblings('.grid-item, .grid-item-member, .grid-item-leader').hide();
      items.show();
    }
  };
  t.switchNav = function(link) {
    $(link)
        .addClass('active')
        .parents('li')
        .siblings('li')
        .children('a')
        .removeClass('active');
  };
  t.init = function() {
    t.container = $('.work-filter, .region-filter');

    if(t.container.length) {
      t.events();
      t.setup();
    }
  };
  return t;
})();

var agencyFilter = (function() {
  var t = {};
  t.container = null;
  t.events = function() {
    $('a', t.container).click(function(e) {
      e.preventDefault();

      t.switchNav($(this));

      var url = $(this).attr('href');

      $('.agencies-wrapper').load(url + ' .agencies-wrapper grid', function() {
        
      });
    });
  };
  t.switchNav = function(link) {
    link
      .parents('li')
      .addClass('current')
      .siblings('li')
      .removeClass('current');
  }
  t.init = function() {
    t.container = $('#disciplines');
    if(t.container.length) {
      t.events();

      var current = getURLParameter('discipline');
      if(current != null) {
        var currentLink = $('a[href="/agencies/directory?discipline=' + current + '"]', t.container);
        if(currentLink.length) {
          t.switchNav(currentLink);
        }
      }
    }
  };
  return t;
})();

$(function() {
  filters.init();
  agencyFilter.init();
});